<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card p-2 mt-2">
        <h2>Information sur le don:</h2>
        <table id="basic-datatable" class="table dt-responsive nowrap w-20">
          <tr>
            <th>Utilisateur</th>
            <td>
              {{
                (donation.customerSurname == donation.customerName) == ""
                  ? "Anonyme"
                  : donation.customerSurname + " " + donation.customerName
              }}
            </td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ donation.customerEmail }}</td>
          </tr>
          <tr>
            <th>Tel</th>
            <td>{{ donation.customerPhoneNumber }}</td>
          </tr>
          <tr>
            <th>Pays</th>
            <td>{{ donation.customerCountry }}</td>
          </tr>
          <tr>
            <th>Montant</th>
            <td>{{ donation.amount }}</td>
          </tr>
          <tr>
            <th>Devise</th>
            <td>{{ donation.currency }}</td>
          </tr>
          <tr>
            <th>Methode</th>
            <td>{{ donation.paymentMethod }}</td>
          </tr>
          <tr>
            <th>Date</th>
            <td>{{ dateFormat(donation.paymentDate) }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-md-6" v-if="donation.comments != ''">
      <h2>Message:</h2>
      <div class="card p-2 alert-success">
        <p>
          {{ donation.comments }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      donation: {
        customerSurname: "",
        customerName: "",
        customerEmail: "",
        amount: "",
        currency: "",
        customerCountry: "",
        customerPhoneNumber: "",
        paymentMethod: "",
        paymentDate: "",
        comments: "",
      },
    };
  },
  methods: {
    async loaDonation() {
      await this.$http
        .get(`/payment/admin-donation-details/${this.$route.params.id}`)
        .then((response) => (this.donation = response.data));
    },
    dateFormat(date) {
      return moment(String(date)).format("MM/DD/YYYY hh:mm");
    },
  },
  beforeMount() {
    this.$nextTick(() => this.loaDonation());
  },
};
</script>
<style lang=""></style>
